import React from "react"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const richTextRendererOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: text => <i className="font-italic">{text}</i>,
    [MARKS.UNDERLINE]: text => <u className="underline">{text}</u>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h2 className="text-3xl sm:text-4xl my-2">{children}</h2>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="text-3xl sm:text-4xl my-2">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-2xl sm:text-3xl my-2">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="text-xl sm:text-2xl my-2">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="text-lg sm:text-xl my-2">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="text-md sm:text-lg my-2">{children}</h6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].value === "") {
        return <br />
      } else {
        return <p className="leading-normal mb-1">{children}</p>
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData, description } = node.data.target

      return (
        <div className="my-10 flex justify-center">
          <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
        </div>
      )
    },
  },
}

const BlogDetail = ({ rawRichTextContent }) => {
  return (
    <div className="container mx-auto max-w-7xl px-6 py-16">
      {renderRichText(rawRichTextContent, richTextRendererOptions)}
    </div>
  )
}

export default BlogDetail
