import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Banner from "../../components/ProjectDetailPage/Banner"
import BlogDetail from "../../components/BlogDetailPage/BlogDetail"

const BlogDetailPage = ({ data }) => {
  const blog = data?.contentfulBlogPost

  return (
    <>
      <GatsbySeo title={`${blog?.title}`} titleTemplate="%s | BESHAUS" />
      <Banner title={`${blog?.title}`} image={blog?.featuredImage} />
      <BlogDetail title={blog?.title} rawRichTextContent={blog?.content} />
    </>
  )
}

export default BlogDetailPage

export const query = graphql`
  query ($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      featuredImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(height: 400)
            __typename
          }
        }
      }
    }
  }
`
